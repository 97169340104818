const config = {
  apiKey: "AIzaSyDsQG4zYGEjomW4-qhnrUsVzXqHCxJU2-0",
  authDomain: "tlm-auth-12fc1.firebaseapp.com",
  databaseURL: "https://tlm-auth-12fc1.firebaseio.com",
  projectId: "tlm-auth-12fc1",
  storageBucket: "tlm-auth-12fc1.appspot.com",
  messagingSenderId: "865470563080",
  appId: "1:865470563080:web:fd843ba5f3dd5ff88222bd",
  measurementId: "G-3KYJELB87Z"
};

export default config
